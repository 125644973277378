var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"LoginBJ"},[_c('div',{staticClass:"centenBox"},[_vm._m(0),_c('div',{staticClass:"BoxRight"},[_c('div',{staticClass:"RightTitle"},[_vm._v("微信扫描二维码登录")]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"RightQRCode",attrs:{"element-loading-text":"拼命加载中"}},[(
            (_vm.qrStatusObj && _vm.qrStatusObj.is_expired === 1) ||
            (_vm.qrStatusObj && _vm.qrStatusObj.is_scan === 0) ||
            (_vm.qrStatusObj && _vm.qrStatusObj.is_expired === 3)
          )?_c('img',{attrs:{"src":_vm.loginObj.qrcode}}):_vm._e(),(_vm.qrStatusObj && _vm.qrStatusObj.is_expired === 0 && _vm.qrStatusObj.is_scan === 1)?_c('div',{staticClass:"success-box"},[_c('img',{attrs:{"src":require("@/assets/image/success-icon.png")}}),_c('div',{staticClass:"success-text"},[_vm._v("扫码成功")]),_c('div',{staticClass:"text"},[_vm._v("请在手机上确认 [ 授权登录 ]")]),_c('div',{staticClass:"refresh",on:{"click":_vm.refreshFn}},[_c('span',[_vm._v("刷新")])])]):_vm._e(),(_vm.qrStatusObj && _vm.qrStatusObj.is_expired === 1)?_c('div',{staticClass:"qr-fixed"},[_c('div',{staticClass:"text"},[_vm._v("请重新刷新二维码")]),_c('div',{staticClass:"qr-Btn",on:{"click":_vm.getLoginQrcodeFn}},[_c('button',[_vm._v("点击刷新")])])]):_vm._e()]),_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BoxLeft"},[_c('div',{staticClass:"leftTitle"},[_c('img',{attrs:{"src":require("@/assets/image/resumeLogin/logo.png"),"alt":""}}),_c('div',{staticClass:"LeftTextTitle"},[_vm._v("简历诊断")])]),_c('div',{staticClass:"leftTwo"},[_c('img',{attrs:{"src":require("@/assets/image/resumeLogin/icon.png"),"alt":""}}),_c('div',{staticClass:"leftText"},[_vm._v("简历写得好不好，一测就知晓")])]),_c('div',{staticClass:"leftTwo centen"},[_c('img',{attrs:{"src":require("@/assets/image/resumeLogin/icon(1).png"),"alt":""}}),_c('div',{staticClass:"leftText"},[_vm._v("诊断简历中的问题教你 突出简历亮点")])]),_c('div',{staticClass:"leftTwo"},[_c('img',{attrs:{"src":require("@/assets/image/resumeLogin/icon(2).png"),"alt":""}}),_c('div',{staticClass:"leftText"},[_vm._v("简历写得好，面试机会少不了")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"RightBootom"},[_c('div',{staticClass:"RightBootomOne"},[_c('span',[_vm._v("欢迎来到简历诊断")]),_c('span',{staticClass:"working"},[_vm._v("登录后解锁更多功能")])]),_c('div',{staticClass:"RightBootomTwo"},[_c('span',[_vm._v("©2019-2023 桂ICP备19009194号")]),_c('span',{staticClass:"business"},[_vm._v("桂公网安备 45010802000184号")])])])
}]

export { render, staticRenderFns }